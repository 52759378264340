import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Import des pages (à créer)
const Home = React.lazy(() => import('./pages/Home'));
const Presentation = React.lazy(() => import('./pages/Presentation'));
const Pourquoi = React.lazy(() => import('./pages/Pourquoi'));
const Services = React.lazy(() => import('./pages/Services'));
const Realisations = React.lazy(() => import('./pages/Realisations'));
const Contact = React.lazy(() => import('./pages/Contact'));
const MentionsLegales = React.lazy(() => import('./pages/MentionsLegales'));
const NoahReseaux = React.lazy(() => import('./pages/NoahReseaux'));
const ServiceExpertisePage = React.lazy(() => import('./pages/ServiceExpertisePage'));
const NosBunkers = React.lazy(() => import('./pages/Services/NosBunkers'));
const ProtocoleConstruction = React.lazy(() => import('./pages/Services/ProtocoleConstruction'));

const LoadingSpinner = () => (
    <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5'
    }}>
        <div style={{
            width: '50px',
            height: '50px',
            border: '5px solid #f3f3f3',
            borderTop: '5px solid #3498db',
            borderRadius: '50%',
            animation: 'spin 1s linear infinite',
        }} />
        <style>{`
            @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }
        `}</style>
        <p style={{
            marginTop: '20px',
            fontSize: '18px',
            color: '#333',
            fontWeight: '500'
        }}>
            Chargement en cours...
        </p>
    </div>
);

const AppRoutes = () => {
    return (
        <React.Suspense fallback={<LoadingSpinner />}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/presentation" element={<Presentation />} />
                <Route path="/pourquoi" element={<Pourquoi />} />
                <Route path="/nos-solutions" element={<NosBunkers />} />
                <Route path="/realisations" element={<Realisations />} />
                <Route path="/contactez-nous" element={<Contact />} />
                <Route path="/mentions-legales" element={<MentionsLegales />} />
                <Route path="/noah-reseaux" element={<Home />} />
                <Route path="/expertise-de-votre-bad" element={<ServiceExpertisePage />} />
                <Route path="/bunkers-nrbc-autonomes" element={<NosBunkers />} />
                <Route path="/protocole-de-construction" element={<ProtocoleConstruction />} />
                <Route path="*" element={<div>Page non trouvée</div>} />
            </Routes>
        </React.Suspense>
    );
};

export default AppRoutes; 