import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa6";
import NoahLogo from "./NoahLogo";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="mainFooter  relative z-20">
      <div className="bg-[#393839] py-8">
        <div className="max-w-screen-xl mx-auto px-4 pb-4 sm:px-6 lg:px-8">
          <h2 className="text-center text-xl text-[#d9c447] mb-10 font-primary">
            {t("Clients et partenaires")}
          </h2>
          <div className="flex flex-wrap justify-center gap-24">
            <a
              href="https://www.alpha-termites.fr"
              target="_blank"
              rel="noopener noreferrer"
              className="opacity-100 hover:opacity-60 transition-all duration-300"
            >
              <img
                src="/images/partenaires/Alpha Termites Isolation.svg"
                alt="Alpha Termites Isolation"
                className="h-12 w-auto"
              />
            </a>

            <a
              href="https://www.wattuneed.com"
              target="_blank"
              rel="noopener noreferrer"
              className="opacity-100 hover:opacity-60 transition-all duration-300"
            >
              <img
                src="/images/partenaires/logo wattuneed.jpg"
                alt="Wattuneed"
                className="h-10 w-auto"
              />
            </a>

            <a
              href="https://www.bunkl.fr"
              target="_blank"
              rel="noopener noreferrer"
              className="opacity-100 hover:opacity-60 transition-all duration-300"
            >
              <img
                src="/images/partenaires/bunkl.png"
                alt="Bunkl Shelters"
                className="h-10 w-auto"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="max-w-screen-xl mx-auto pt-10 pb-4 px-4 overflow-hidden sm:px-6 lg:px-8">
          <nav className="-mx-5 -my-2 flex flex-wrap justify-center">
            <div className="px-5 py-2">
              <Link
                to="/"
                className="text-base text-gray-500 hover:text-gray-900"
              >
                {t("Accueil")}
              </Link>
            </div>
            <div className="px-5 py-2">
              <Link
                to="/mentions-legales"
                className="text-base text-gray-500 hover:text-gray-900 "
              >
                {t("Mentions légales")}
              </Link>
            </div>
            <div className="px-5 py-2">
              <Link
                to="/contactez-nous"
                className="text-base text-gray-500 hover:text-gray-900 "
              >
                {t("Contact")}
              </Link>
            </div>
          </nav>

          {/* Réseaux sociaux - commentés dans l'original */}
          {/* <div className="mt-8 flex justify-center space-x-6">
                    <a href="https://www.facebook.com/NoahResilience" className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Facebook</span>
                        <FaFacebook className="h-6 w-6" />
                    </a>
                    <a href="https://www.instagram.com/noahresilience/" className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Instagram</span>
                        <FaInstagram className="h-6 w-6" />
                    </a>
                    <a href="https://twitter.com/NoahResilience" className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Twitter</span>
                        <FaTwitter className="h-6 w-6" />
                    </a>
                    <a href="https://www.youtube.com/channel/UCQ4Z6j1ZqZ0X6Z9Z4Z6j1ZQ" className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">YouTube</span>
                        <FaYoutube className="h-6 w-6" />
                    </a>
                </div> */}

          <div className="mt-8 flex justify-center space-x-6">
            <NoahLogo />
          </div>

          <div className="mt-8 flex justify-center space-x-6">
            <p className="text-sm text-gray-500">
              © {new Date().getFullYear()} .{" "}
              <Link to="/" className="text-yellow-700 hover:text-yellow-600">
                Noah Resilience
              </Link>
              <span className="ml-4">{t("Tous droits réservés")} . </span>
              Réalisation:{" "}
              <a
                href="https://loopus.tech"
                target="_blank"
                rel="noopener noreferrer"
                className="text-yellow-700 hover:text-yellow-600"
              >
                Loopus Tech
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
