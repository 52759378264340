import React from 'react';
import { Link } from 'react-router-dom';

const NoahLogo = () => {
    return (
        <Link to="/" className="flex items-center text-[#d9c447] hover:opacity-80">
            <img src="/images/logoHeader.png" className="h-16 mr-1 -mt-3" alt="NOAH Résilience" />
            <div className="flex flex-col items-start">
                {/* <span className="font-primary self-start text-2xl whitespace-nowrap xl:-mt-3">
                    NOAH Résilience
                </span> */}
                <span className="font-secondary self-center text-xs whitespace-nowrap  -mt-1 hidden xl:block">
                Bunkers NRBC autonomes,<br/>maisons fortifiées et panic rooms
                </span>
            </div>
        </Link>
    );
};

export default NoahLogo; 